/* src/components/PraxisInfo.css (or PraxisDetails.css, if you're merging) */

.praxis-details {
  background-color: #fff;
}

.praxis-details h2 {
  font-weight: 700;
  letter-spacing: 0.05em;
}

.praxis-details p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

/* Optional styling for the image container */
.praxis-details .text-center img {
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
}

.custom-btn {
  background-color: transparent;
  border: 2px solid var(--color-primary) !important;
  color: var(--color-primary) !important;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* On hover, fill with pink */
.custom-btn:hover {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}

.btn-custom {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
  border: none !important;
  font-size: 0.875rem;    /* Smaller font size */
  padding: 0.375rem 0.75rem;  /* Reduced padding */
  border-radius: 0.25rem; /* Optional: adjust border-radius if desired */
  transition: background-color 0.3s ease;
}

.btn-custom:hover {
  background-color: #C2528C !important;
  color: var(--color-white) !important;
}
