.back-button {
    background-color: var(--color-primary); /* Your pink primary color */
    color: var(--color-white);                /* White text for contrast */
    border: none;
    padding: 0.5rem 1.25rem;                   /* Adjust for appropriate size */
    border-radius: 25px;                      /* Rounded button */
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 2rem;
    display: inline-block;
    text-align: center;
  }
  
  .back-button:hover {
    background-color: #C2528C; /* Slightly darker pink */
    transform: translateY(-2px); /* Subtle lift on hover */
  }
  
  .back-button:active {
    transform: translateY(0);
  }
  