/* src/components/ImpressumFooter.css */

.impressum-footer {
    background-color: var(--color-accent); /* Use your softer accent color */
    font-size: 0.75rem;
    color: #666;
    padding: 0.5rem 0;
    border-top: 1px solid #ddd;
  }
  
  
  .impressum-footer .container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .impressum-footer p {
    margin: 0.25rem 0;
    line-height: 1.4;
  }
  
  .impressum-footer a {
    color: var(--color-primary);
    text-decoration: none;
  }
  
  .impressum-footer a:hover {
    text-decoration: underline;
  }
  
  .footer-title2 {
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 0.05em;
    color: #000; /* Sets the text color to black */
  }
  