/* src/components/KontaktTermine.css */

.kontakt-termine-section {
    background-color: #fff; /* Clean white background */
    
  }
  
  .kontakt-termine-section h2 {
    font-weight: 700;
    letter-spacing: 0.05em;
  }
  
  .kontakt-termine-section p {
    font-size: 1rem;
    color: #333;
  }
  
  .kontakt-termine-section .lead {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .kontakt-termine-section .btn.btn-dark {
    background-color: var(--color-primary) !important;
    color: var(--color-white) !important;
    border: none !important;
  }
  
  .custom-btn {
    background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
  border: none !important;
  }
  
  .custom-btn:hover {
    background-color: #C2528C!important; /* Manually calculated darker shade */
  }
  .btn-custom {
    background-color: var(--color-primary) !important;
    color: var(--color-white) !important;
    border: none !important;
    font-size: 0.875rem;    /* Smaller font size */
    padding: 0.375rem 0.75rem;  /* Reduced padding */
    border-radius: 0.25rem; /* Optional: adjust border-radius if desired */
    transition: background-color 0.3s ease;
  }
  
  .btn-custom:hover {
    background-color: #C2528C !important;
    color: var(--color-white) !important;
  }
  
  